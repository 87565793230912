function PhotoLayout({images}) {
    return (
        <div className='photo-layout-container'>
            <ul className='photo-list'>
                {images.map(item => {
                    return <li className='photo-list-item'><img src={item} className='photo-item' alt='' /></li>
                })}
            </ul>
        </div>
    )
}

export default PhotoLayout

/* {images.images.forEach((value, key) => {
    return <li className='photo-list-item'><img src={value} className='photo-item' /></li>
})} */