import { Switch, Route } from 'react-router-dom'
import React from 'react'
import ErrorPage from './errorpage';
import About from './about'
import Body from './body'
import Article from './article'
import Posts from './posts'

function Routes() {
    return (
        <Switch>
            <Route exact path='/' component={Body} />
            <Route path='/about' component={About} />
            <Route path='/stuff' component={Posts} />
            <Route path='/post/:blogId' component={Article} />
            <Route path='*' component={ErrorPage} />
        </Switch>
    )
}

export default Routes