import React, { useState, useEffect } from 'react'
import db, { storage } from '../firebase.config'
import { collection, getDocs } from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage"
import { useParams, Redirect } from "react-router-dom"
import HalfSection from './halfsection'
import PhotoLayout from './photolayout'

export default function Article() {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let params = useParams();

    useEffect(() => {
        (async function () {
            const querySnapshot = await getDocs(collection(db, "blogs"));

            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                coverImgUrl: [],
                portraitImgUrl: [],
                landscapeImgUrl: [],
                imagesFullUrl: new Map()
            })).filter(post => post.id === params.blogId);

            // Create a reference with an initial file path and name
            if (data.length > 0) {
                const map = new Map(Object.entries(data[0].images));
                map.forEach((value, key) => {
                        const pathReference = ref(storage, data[0].imagesDir + value);
                        // Get the download URL
                        getDownloadURL(pathReference).then((url) => {
                            data[0].imagesFullUrl.set(key, url);
                            if (data[0].imagesFullUrl.size === map.size) {
                                setBlogs(data);
                                setIsLoading(false);
                            }
                        }).catch((error) => {
                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (error.code) {
                                case 'storage/object-not-found':
                                    // File doesn't exist
                                    console.log("case 1");
                                    break;
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    console.log("case 2");
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    console.log("case 3");
                                    break;
                                // ...
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect the server response
                                    console.log("case 4");
                                    break;
                                default:
                                    console.log("case 5");
                                    break;
                            }
                        })
                })
            } else {
                setBlogs(null);
                setIsLoading(false);
            }
        })();
    });

    return (
        <section className="feature-container"> {
            isLoading ? <div></div> :
            blogs ? blogs.map((blog, index) => {
                let paragraphs = blog.body.split("\\n");
                return (
                    <section key={blog.id} className="post">
                        <img src={blog.imagesFullUrl.get("0")} alt='' />
                        <h1>{blog.title}</h1>
                        <p>{paragraphs[0]}</p>
                        <HalfSection paragraph={paragraphs[1]} image={blog.imagesFullUrl.get("1")} alignRight={false} />
                        <HalfSection paragraph={paragraphs[2]} image={blog.imagesFullUrl.get("2")} alignRight={true} />
                        <p>
                                    It felt like I was fading to black, as if I was just a shell of my former self, and there was no hope to be found. In my case what helped me was practicing mindfulness meditation. Meditating put things into perspective and it calmed my busy mind. Sometimes I still get depressed and anxious, but I try to remind myself that I will not always feel like this. Tomorrow is a brand new day with endless possibilities. It is important to remember that no matter how dark it may seem, there is always a sliver of light to be found. Find the light, and light up the darkness.
                        </p>
                        <PhotoLayout images={Array.from(blog.imagesFullUrl.values())} />
                    </section>
                )
            }) : <Redirect to="/errorpage" />
        }
        </section>
    )
}