import React from 'react';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <header className="site-header">
      <nav>
        <ul>
          <li key={"home"}>
            <Link to={{ pathname: '/' }}>Noel's Website</Link>
          </li>
        </ul>
        <ul>
        <li key={"posts"}>
            <Link to={{ pathname: '/stuff' }}>Posts</Link>
          </li>
        </ul>
        <ul>
        <li key={"about"}>
            <Link to={{ pathname: '/about' }}>About</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header