function ErrorPage() {
    return (
        <section className="feature-container">
            <header className="error-header">
                <h1>This page does not exist</h1>
            </header>
        </section>
    )
}

export default ErrorPage