function Item({ isRightSide, paragraph, image }) {
    console.log(isRightSide);
    if (isRightSide) {
        return (
            <div className='first-div'>
                <div className='inner'>
                    <p>
                        {paragraph}
                    </p>
                </div>
                <div className="inner-second">
                    <img src={image} className="align-image" alt='' />
                </div>
            </div>
        );
    } else {
        return (
            <div className='first-div'>
                <div className="inner-second">
                    <p>
                        {paragraph}
                    </p>
                </div>
                <div className='inner'>
                    <img src={image} className="align-image" alt='' />
                </div>
            </div>
        );     
    }
}
  
function HalfSection({paragraph, image, alignRight}) {
    return (
        <Item 
            isRightSide={alignRight} 
            paragraph={paragraph}
            image={image} 
        />
    );
}

export default HalfSection
  